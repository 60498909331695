//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding bootstrap variables.
//  ================================================================================================

// Bootstrap variables
// $primary: #4186e0;

$white: #fff !default;
$gray-50: #f6f6f6 !default;
$gray-100: #babfc7 !default; // $gray-lightest
$gray-200: #ededed !default; // $gray-lighter
$gray-300: #dae1e7 !default; // $gray-light
$gray-400: #636363 !default;
$gray-500: #adb5bd !default;
$gray-600: #b8c2cc !default; // $gray
$gray-700: #4e5154 !default;
$gray-800: #1e1e1e !default; // $gray-dark
$gray-900: #2a2e30 !default;
$black: #22292f !default; // 231f48 22292f

// $purple: #0f0a4e !default; //$primary
$purple: #0b2046 !default; // $primary
$green: #28c76f !default; //$success
$blue: #006ca0 !default; //$info
$orange: #ff9f43 !default; //$warning
$red: #ea5455 !default; //$danger,
$main:#ff6633 !default; //mainbase

$primary: $main !default;
$secondary: #82868b !default;
$info: $blue !default;
$warning: $orange !default;
$light: $gray-50 !default;
$dark: #4b4b4b !default;


.bg-danger {
    background-color: $main !important;
}

.btn-danger,
.btn-info {
    background-color: $main !important;
    border-color: $main !important;
}

.btn-info:focus, .btn-info:active, .btn-info.active {
    background-color: $main !important;
    border-color: $main !important;
    color: #fff !important;
}